import { Box, Stack } from "@mui/joy";
import { memo } from "react";
import { HomeScreen, NotificationBanner } from "features/homeScreen";

const HomePage = memo(() => {
  return (
    <Stack
      alignItems="center"
      flexDirection="column"
      height="100%"
      overflow="auto"
      padding={4}
      position="relative"
      width="100%"
    >
      <Box style={{ left: 0, position: "absolute", right: 0, top: 0 }}>
        <NotificationBanner />
      </Box>
      <HomeScreen />
    </Stack>
  );
});

export default HomePage;
