import { type DataSourceType } from "@decentriq/graphql/dist/types";
import { useMemo } from "react";
import { useOrganizationFeatures, useOrganizationPreferences } from "hooks";

interface GetDisabledAndFilteredImportSyncItemsHookResult {
  getDisabledItems: () => DataSourceType[];
  getFilteredItems: () => DataSourceType[];
}

const useGetDisabledAndFilteredImportSyncItems =
  (): GetDisabledAndFilteredImportSyncItemsHookResult => {
    const { organizationId, isLoading: isLoadingOrganizationPreferences } =
      useOrganizationPreferences();
    const { importConnectors } = useOrganizationFeatures({
      organizationId,
      skip: isLoadingOrganizationPreferences,
    });
    return useMemo(
      () => ({
        getDisabledItems: () =>
          Object.entries(importConnectors)
            .filter(([, { isDisabled }]) => isDisabled)
            .map(([key]) => key as DataSourceType),
        getFilteredItems: () =>
          Object.entries(importConnectors)
            .filter(([, { isHidden }]) => !isHidden)
            .map(([key]) => key as DataSourceType),
      }),
      [importConnectors]
    );
  };

export default useGetDisabledAndFilteredImportSyncItems;
