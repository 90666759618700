import { InfoTooltip } from "@decentriq/components";
import { Box, Typography, useTheme } from "@mui/joy";
import { type BarDatum, ResponsiveBar } from "@nivo/bar";
import { memo, useMemo } from "react";
import { useDataLabContext } from "features/dataLabs";

const SegmentDistributionChart: React.FC = () => {
  const {
    dataLab: { data: dataLab },
  } = useDataLabContext();
  const { palette } = useTheme();

  const { statistics: dataLabStatistics } = dataLab || {};
  const {
    share_of_users_per_segment_distribution: shareOfUsersPerSegmentDistribution,
  } = dataLabStatistics || {};

  const segmentDistributionChartData: BarDatum[] = useMemo(
    () =>
      shareOfUsersPerSegmentDistribution
        ? Object.entries(shareOfUsersPerSegmentDistribution)
            .sort(
              ([, value1], [, value2]) =>
                (value2 as number) - (value1 as number)
            )
            .map(([segmentName, distributionValue]) => ({
              segment: segmentName,
              value: distributionValue as number,
            }))
        : [],
    [shareOfUsersPerSegmentDistribution]
  );

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: segmentDistributionChartData.length * 10 + 150,
        width: "90%",
      }}
    >
      <Typography>
        Share of users per segment
        <InfoTooltip
          tooltip="Each bar of this chart represents the share of all users which are in this segment. For example, a bar for the Leisure segment with a value of 20% means that 20% of all users are in the Leisure segment. As users may be in multiple segments, these bars are not expected to sum up to 100%."
          top="2px"
        />
      </Typography>
      <ResponsiveBar
        axisBottom={{
          format: ">-.0%",
          legend: "Share of users",
          legendOffset: 40,
          legendPosition: "middle",
          tickPadding: 5,
          tickSize: 5,
        }}
        colors={palette.chart[500]}
        data={segmentDistributionChartData}
        enableGridX={true}
        enableGridY={false}
        enableLabel={false}
        indexBy="segment"
        key="segment"
        layout="horizontal"
        margin={{
          bottom: 80,
          left: 250,
          right: 90,
          top: 8,
        }}
        padding={0.2}
        tooltipLabel={({ data }) => `${data.segment}`}
        valueFormat=">-.2%"
      />
    </Box>
  );
};

SegmentDistributionChart.displayName = "SegmentDistributionChart";

export default memo(SegmentDistributionChart);
