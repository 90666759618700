import { DqCard, DqCardContent } from "@decentriq/components";
import { Box, Grid, Typography, useTheme } from "@mui/joy";
import {
  type HeatMapDatum,
  type HeatMapSerie,
  ResponsiveHeatMap,
} from "@nivo/heatmap";
import { groupBy } from "lodash";
import { memo, useMemo } from "react";
import { useDataLabContext } from "features/dataLabs";

const DemographicDistributionCharts: React.FC = () => {
  const {
    dataLab: { data: dataLab },
  } = useDataLabContext();
  const { palette } = useTheme();
  const { statistics: dataLabStatistics, demographicsDataset } = dataLab || {};
  const { demographics_distributions: demographicsDistributions } =
    dataLabStatistics || {};
  // Group data by age group value
  const groupedData = useMemo(
    () =>
      demographicsDataset
        ? groupBy(
            Object.entries(demographicsDistributions),
            ([segmentName]) => {
              const [ageGroup] = segmentName.split("||");
              return ageGroup;
            }
          )
        : [],
    [demographicsDistributions, demographicsDataset]
  );
  const allUsersChartData: HeatMapSerie<HeatMapDatum, {}>[] = useMemo(
    () =>
      Object.entries(groupedData).map(([ageGroup, segmentsData]) => ({
        data: segmentsData.map(([segment, values]) => {
          const [, gender] = segment.split("||");
          return {
            x: gender,
            y: (values as [number, number])[0],
          };
        }),
        id: ageGroup,
      })),
    [groupedData]
  );
  const effectiveUsersChartData: HeatMapSerie<HeatMapDatum, {}>[] = useMemo(
    () =>
      Object.entries(groupedData).map(([ageGroup, segmentsData]) => ({
        data: segmentsData.map(([segment, values]) => {
          const [, gender] = segment.split("||");
          return {
            x: gender,
            y: (values as [number, number])[1],
          };
        }),
        id: ageGroup,
      })),
    [groupedData]
  );
  return (
    <Grid container={true} rowSpacing={2} xs={12}>
      <Grid height="350px" md={6} xs={12}>
        <DqCard sx={{ height: "100%", marginRight: { md: 2, xs: 0 } }}>
          <DqCardContent sx={{ height: "100%" }}>
            <Box height="100%" width="100%">
              <Typography textAlign="center">
                Demographics distribution (All users)
              </Typography>
              <ResponsiveHeatMap
                axisBottom={{
                  legend: "Gender",
                  legendOffset: 36,
                  legendPosition: "middle",
                }}
                axisLeft={{
                  legend: "Age group",
                  legendOffset: -58,
                  legendPosition: "middle",
                  tickPadding: 5,
                  tickSize: 5,
                }}
                colors={{
                  colors: [
                    palette.chart[50],
                    palette.chart[300],
                    palette.chart[500],
                  ],
                  divergeAt: 0.5,
                  type: "diverging",
                }}
                data={allUsersChartData}
                emptyColor={"var(--joy-palette-neutral-100)"}
                inactiveOpacity={0.5}
                margin={{
                  bottom: 64,
                  left: 64,
                  top: 24,
                }}
                valueFormat=">-.2%"
              />
            </Box>
          </DqCardContent>
        </DqCard>
      </Grid>
      <Grid height="350px" md={6} xs={12}>
        <DqCard sx={{ height: "100%" }}>
          <DqCardContent sx={{ height: "100%" }}>
            <Box height="100%" width="100%">
              <Typography textAlign="center">
                Demographics distribution (Users with a matching ID)
              </Typography>
              <ResponsiveHeatMap
                axisBottom={{
                  legend: "Gender",
                  legendOffset: 36,
                  legendPosition: "middle",
                }}
                axisLeft={{
                  legend: "Age group",
                  legendOffset: -58,
                  legendPosition: "middle",
                  tickPadding: 5,
                  tickSize: 5,
                }}
                colors={{
                  colors: [
                    palette.chart[50],
                    palette.chart[300],
                    palette.chart[500],
                  ],
                  divergeAt: 0.5,
                  type: "diverging",
                }}
                data={effectiveUsersChartData}
                emptyColor={"var(--joy-palette-neutral-100)"}
                inactiveOpacity={0.5}
                margin={{
                  bottom: 64,
                  left: 64,
                  top: 24,
                }}
                valueFormat=">-.2%"
              />
            </Box>
          </DqCardContent>
        </DqCard>
      </Grid>
    </Grid>
  );
};
DemographicDistributionCharts.displayName = "DemographicDistributionCharts";

export default memo(DemographicDistributionCharts);
