import { DqCard, DqCardContent } from "@decentriq/components";
import { faCopy, faHashtagLock } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Stack, Tooltip, Typography } from "@mui/joy";
import { id } from "date-fns/locale";
import { useSnackbar } from "notistack";
import { memo } from "react";
import { useDataRoomDrawerContext } from "components/entities/dataRooms/DataRoomDrawer/contexts";
import { useCopyToClipboard } from "hooks";

const DataRoomId = memo(() => {
  const { dataRoomId } = useDataRoomDrawerContext();
  const { enqueueSnackbar } = useSnackbar();
  const [, copyToClipboard] = useCopyToClipboard();

  return (
    <DqCard>
      <DqCardContent sx={{ alignItems: "flex-start" }}>
        <Typography level="body-sm">
          <strong>ID</strong>
        </Typography>
        <Stack alignItems="center" flexDirection="row" width="100%">
          <FontAwesomeIcon fixedWidth={true} icon={faHashtagLock} />
          <Tooltip title={dataRoomId}>
            <Typography level="body-sm" noWrap={true}>
              {dataRoomId}
            </Typography>
          </Tooltip>
          <Tooltip title={<span>Copy Data Clean Room id</span>}>
            <IconButton
              onClick={() => {
                if (id) {
                  void copyToClipboard(dataRoomId);
                  enqueueSnackbar("DataRoom id copied");
                }
              }}
              size="sm"
              sx={{ marginLeft: "auto" }}
            >
              <FontAwesomeIcon fixedWidth={true} icon={faCopy} />
            </IconButton>
          </Tooltip>
        </Stack>
      </DqCardContent>
    </DqCard>
  );
});

DataRoomId.displayName = "DataRoomId";

export default DataRoomId;
