import { useApolloClient } from "@apollo/client";
import { CreateDatasetImportDocument } from "@decentriq/graphql/dist/types";
import { Button } from "@mui/joy";
import saveAs from "file-saver";
import snakeCase from "lodash/snakeCase";
import { type SnackbarKey } from "notistack";
import {
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  type AudienceSizesHookResult,
  type MediaDataRoomJobHookResult,
  MediaDataRoomJobInput,
  type MediaDataRoomJobResultTransform,
  type MediaDataRoomRequestKey,
  useAudienceSizes,
  useMediaDataRoomJob,
  useMediaDataRoomLazyJob,
} from "features/mediaDataRoom/hooks";
import {
  type Audience,
  type AudiencesFileStructure,
} from "features/mediaDataRoom/models";
import {
  mapMediaDataRoomErrorToSnackbar,
  useDataRoomSnackbar,
  useSafeContext,
} from "hooks";
import { useMediaDataRoom } from "../MediaDataRoomContext";
import { useMediaDataRoomInsightsData } from "../MediaDataRoomInsightsDataContext";

export interface PublisherAudiencesContextValue {
  audiences: MediaDataRoomJobHookResult<Audience[]>;
  isExportingAudience: Record<string, boolean>;
  isAudiencesDataOutdated: boolean;
  canResetAudiences: false;
  resetAudiences: () => never;
  downloadAudience: (audience: Audience) => Promise<void>;
  exportAudienceAsDataset: (audience: Audience) => Promise<void>;
  audienceSizes: AudienceSizesHookResult;
}

const PublisherAudiencesContext =
  createContext<PublisherAudiencesContextValue | null>(null);

PublisherAudiencesContext.displayName = "PublisherAudiencesContext";

export const usePublisherAudiences = () =>
  useSafeContext(PublisherAudiencesContext);

type PublisherAudiencesWrapperProps = React.PropsWithChildren;

const PublisherAudiencesWrapper = memo<PublisherAudiencesWrapperProps>(
  ({ children }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const navigate = useNavigate();
    const apolloClient = useApolloClient();
    const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];
    const {
      dataRoomId,
      dataRoomName,
      driverAttestationHash,
      isDeactivated,
      isPublisher,
    } = useMediaDataRoom();
    const {
      datasets: { fetch: fetchDatasets },
      publishedDatasetsHashes: { hasRequiredData },
      publishedDatasetsHashes,
      session,
    } = useMediaDataRoomInsightsData();
    const transform = useCallback<
      MediaDataRoomJobResultTransform<{
        // This field is part of the workaround to show when audiences.json is outdated
        // it's used to show a warning that datasets are outdated and the audiences.json needs to be updated but current user is not allowed to do so
        isOutdated?: boolean;
        audiences: Audience[];
      }>
    >(
      async (zip) => {
        const audiencesFile = zip.file("audiences.json");
        if (audiencesFile === null) {
          throw new Error("audiences.json not found in zip");
        }
        const audiencesFileStructure = JSON.parse(
          await audiencesFile.async("string")
        ) as AudiencesFileStructure;
        if (audiencesFileStructure.advertiser_manifest_hash === null) {
          return { audiences: audiencesFileStructure.audiences };
        }
        const hashes = await fetchDatasets();
        if (
          audiencesFileStructure.advertiser_manifest_hash ===
            hashes.advertiserDatasetHash &&
          audiencesFileStructure.matching_manifest_hash ===
            hashes.publisherDatasetsHashes.matchingDatasetHash &&
          audiencesFileStructure.embeddings_manifest_hash ===
            hashes.publisherDatasetsHashes.embeddingsDatasetHash &&
          audiencesFileStructure.demographics_manifest_hash ===
            hashes.publisherDatasetsHashes.demographicsDatasetHash &&
          audiencesFileStructure.segments_manifest_hash ===
            hashes.publisherDatasetsHashes.segmentsDatasetHash
        ) {
          return { audiences: audiencesFileStructure.audiences };
        }
        return { audiences: [], isOutdated: true };
      },
      [fetchDatasets]
    );
    const audiencesJobResult = useMediaDataRoomJob({
      input: MediaDataRoomJobInput.create(
        "getAudiencesForPublisher",
        dataRoomId,
        driverAttestationHash,
        publishedDatasetsHashes
      ),
      requestCreator: useCallback(
        (dataRoomIdHex, scopeIdHex) => ({
          dataRoomIdHex,
          scopeIdHex,
        }),
        []
      ),
      session,
      skip: !(
        !isDeactivated &&
        isPublisher &&
        !!dataRoomId &&
        !!driverAttestationHash &&
        hasRequiredData
      ),
      transform,
    });
    const audiences = audiencesJobResult.computeResults?.audiences;
    useEffect(() => {
      if (audiencesJobResult.error) {
        setErrorSnackbarId(
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(
              audiencesJobResult.error,
              "Unable to fetch audiences"
            )
          )
        );
      } else {
        setErrorSnackbarId((snackbarId) => {
          if (snackbarId) {
            return undefined;
          }
          return snackbarId;
        });
      }
    }, [enqueueSnackbar, audiencesJobResult.error, setErrorSnackbarId]);
    const [isExportingAudience, setIsExportingAudience] = useState<
      Record<string, boolean>
    >({});
    const transformAudienceForDownload = useCallback<
      MediaDataRoomJobResultTransform<string>
    >(async (zip) => {
      const audienceUsersFile = zip.file("audience_users.csv");
      if (audienceUsersFile === null) {
        throw new Error("audience_users.csv not found in zip");
      }
      const audienceUsersCsv = await audienceUsersFile.async("string");
      if (!audienceUsersCsv) {
        throw new Error("Audience is empty");
      }
      return audienceUsersCsv;
    }, []);
    const [getAudienceUserListForPublisherLal] = useMediaDataRoomLazyJob({
      input: MediaDataRoomJobInput.create(
        "getAudienceUserListForPublisherLal",
        dataRoomId,
        driverAttestationHash,
        publishedDatasetsHashes
      ),
      session,
      transform: transformAudienceForDownload,
    });
    const [getAudienceUserListForPublisher] = useMediaDataRoomLazyJob({
      input: MediaDataRoomJobInput.create(
        "getAudienceUserListForPublisher",
        dataRoomId,
        driverAttestationHash,
        publishedDatasetsHashes
      ),
      session,
      transform: transformAudienceForDownload,
    });
    const downloadAudience = useCallback(
      async (audience: Audience) => {
        try {
          if (!publishedDatasetsHashes.hasRequiredData) {
            throw new Error(
              "Getting audience requires both the publisher and advertiser dataset uploaded and non-empty activated audiences config published"
            );
          }
          setIsExportingAudience((current) => ({
            ...current,
            [audience.id]: true,
          }));
          const payloadParams = session!.compiler.abMedia.getParameterPayloads(
            audience.id,
            audiences || []
          );
          let fileContent;
          if (payloadParams?.lal) {
            fileContent = await getAudienceUserListForPublisherLal({
              requestCreator: (dataRoomIdHex, scopeIdHex) => ({
                dataRoomIdHex,
                generateAudience: payloadParams.generate,
                lalAudience: payloadParams.lal!,
                scopeIdHex,
              }),
              updateInput: (input) => input.withResourceId(audience.id),
            });
          } else {
            fileContent = await getAudienceUserListForPublisher({
              requestCreator: (dataRoomIdHex, scopeIdHex) => ({
                dataRoomIdHex,
                generateAudience: payloadParams.generate,
                scopeIdHex,
              }),
              updateInput: (input) => input.withResourceId(audience.id),
            });
          }
          const filename = `${dataRoomName}-${audience.mutable.name}.csv`;
          const file = new File([fileContent], filename, {
            type: "application/octet-stream;charset=utf-8",
          });
          saveAs(file);
        } catch (error) {
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(
              error,
              "Unable to download audience"
            )
          );
        } finally {
          setIsExportingAudience((current) => ({
            ...current,
            [audience.id]: false,
          }));
        }
      },
      [
        dataRoomName,
        session,
        audiences,
        enqueueSnackbar,
        publishedDatasetsHashes.hasRequiredData,
        getAudienceUserListForPublisherLal,
        getAudienceUserListForPublisher,
      ]
    );
    const exportAudienceAsDataset = useCallback(
      async (audience: Audience) => {
        try {
          if (!publishedDatasetsHashes.hasRequiredData) {
            throw new Error(
              "Storing an audience as dataset requires both the publisher and advertiser dataset uploaded"
            );
          }
          setIsExportingAudience((current) => ({
            ...current,
            [audience.id]: true,
          }));
          const payloadParams = session!.compiler.abMedia.getParameterPayloads(
            audience.id,
            audiences || []
          );
          const isLalAudience = Boolean(payloadParams.lal);
          const computeNodeId: MediaDataRoomRequestKey = isLalAudience
            ? "getAudienceUserListForPublisherLal"
            : "getAudienceUserListForPublisher";
          const filename = `${dataRoomName}-${audience.mutable.name}.csv`;
          const parameters: { computeNodeName: string; content: string }[] = [
            {
              computeNodeName: "generate_audience.json",
              content: JSON.stringify(payloadParams.generate),
            },
          ];
          if (isLalAudience) {
            parameters.push({
              computeNodeName: "lal_audience.json",
              content: JSON.stringify(payloadParams.lal),
            });
          }
          await apolloClient.mutate({
            mutation: CreateDatasetImportDocument,
            variables: {
              input: {
                compute: {
                  computeNodeId: snakeCase(computeNodeId),
                  dataRoomId,
                  driverAttestationHash,
                  importFileWithName: "audience_users.csv",
                  isHighLevelNode: false,
                  parameters,
                  renameFileTo: filename,
                  shouldImportAllFiles: false,
                  shouldImportAsRaw: false,
                },
                datasetName: filename,
              },
            },
          });
          enqueueSnackbar(
            `"${audience.mutable.name}" result is being stored. Please check the status in the 'Datasets' page.`,
            {
              action: (
                <Button onClick={() => navigate("/datasets/external")}>
                  Go to Datasets
                </Button>
              ),
            }
          );
        } catch (error) {
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(
              error,
              "Unable to export audience"
            )
          );
        } finally {
          setIsExportingAudience((current) => ({
            ...current,
            [audience.id]: false,
          }));
        }
      },
      [
        dataRoomName,
        navigate,
        enqueueSnackbar,
        publishedDatasetsHashes.hasRequiredData,
        apolloClient,
        dataRoomId,
        session,
        driverAttestationHash,
        audiences,
      ]
    );
    const audienceSizes = useAudienceSizes({
      audiences,
      dataRoomId,
      driverAttestationHash,
      isPublisher: true,
      publishedDatasetsHashes,
      session,
    });
    const contextValue = useMemo<PublisherAudiencesContextValue>(
      () => ({
        audienceSizes,
        // This override is needed to implement the same interface and do no expose the isOutdated field to the consumers of this context;
        // it should be removed as soon as we get rid of the nasty workaround for outdated audiences.json
        audiences: {
          ...(audiencesJobResult as MediaDataRoomJobHookResult<unknown> as MediaDataRoomJobHookResult<
            Audience[]
          >),
          computeResults: audiences,
          setCacheData: (audiences: Audience[] | undefined) =>
            audiencesJobResult.setCacheData(
              audiences ? { audiences } : undefined
            ),
        },
        canResetAudiences: false,
        downloadAudience,
        exportAudienceAsDataset,
        isAudiencesDataOutdated:
          audiencesJobResult.computeResults?.isOutdated ?? false,
        isExportingAudience,
        resetAudiences: () => {
          throw new Error("Resetting audience is not allowed for publisher");
        },
      }),
      [
        audiencesJobResult,
        isExportingAudience,
        downloadAudience,
        audiences,
        exportAudienceAsDataset,
        audienceSizes,
      ]
    );
    return (
      <PublisherAudiencesContext.Provider value={contextValue}>
        {children}
      </PublisherAudiencesContext.Provider>
    );
  }
);

PublisherAudiencesWrapper.displayName = "PublisherAudiencesWrapper";

export default PublisherAudiencesWrapper;
