import { useOrganizationMicrosoftDspMemberQuery } from "@decentriq/graphql/dist/hooks";
import { DataTargetType } from "@decentriq/graphql/dist/types";
import { useMemo } from "react";
import { useOrganizationFeatures, useOrganizationPreferences } from "hooks";
import { MicrosoftDspMember } from "models";

interface GetDisabledAndFilteredExportSyncItemsHookResult {
  getDisabledItems: () => DataTargetType[];
  getFilteredItems: () => DataTargetType[];
}

const useGetDisabledAndFilteredExportSyncItems =
  (): GetDisabledAndFilteredExportSyncItemsHookResult => {
    const { organizationId, isLoading: isLoadingOrganizationPreferences } =
      useOrganizationPreferences();
    const { exportConnectors } = useOrganizationFeatures({
      organizationId,
      skip: isLoadingOrganizationPreferences,
    });
    const { data: organizationMicrosoftDspMemberQueryData } =
      useOrganizationMicrosoftDspMemberQuery({
        skip: !organizationId,
        variables: { organizationId },
      });
    const microsoftDspMember = organizationMicrosoftDspMemberQueryData
      ?.organization?.microsoftDspMember as MicrosoftDspMember;
    const isMicrosoftDspDisabled =
      !microsoftDspMember || microsoftDspMember === MicrosoftDspMember.None;
    return useMemo(
      () => ({
        getDisabledItems: () =>
          Object.entries(exportConnectors)
            .filter(
              ([key, { isDisabled }]) =>
                isDisabled ||
                ((key as DataTargetType) === DataTargetType.MicrosoftDsp &&
                  isMicrosoftDspDisabled)
            )
            .map(([key]) => key as DataTargetType),
        getFilteredItems: () =>
          Object.entries(exportConnectors)
            .filter(([, { isHidden }]) => !isHidden)
            .map(([key]) => key as DataTargetType),
      }),
      [exportConnectors, isMicrosoftDspDisabled]
    );
  };

export default useGetDisabledAndFilteredExportSyncItems;
