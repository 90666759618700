import { Box, Divider, Stack, Typography } from "@mui/joy";
import { Fragment } from "react";
import { DataRoomsList, RecentDataRoomsBlock } from "components";
import { DataRoomCreateButton } from "features/dataRoom";

const DataRoomsPage = () => {
  return (
    <Fragment>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ minHeight: 68, p: 2 }}
      >
        <Typography level="h4">Data clean rooms</Typography>
        <DataRoomCreateButton />
      </Stack>
      <Stack spacing={0} sx={{ flex: 1, overflow: "hidden" }}>
        <Box sx={{ pb: 2, px: 2 }}>
          <RecentDataRoomsBlock />
        </Box>
        <Divider />
        <DataRoomsList />
      </Stack>
    </Fragment>
  );
};

export default DataRoomsPage;
