import { DqCard, DqCardContent } from "@decentriq/components";
import { Grid, Typography } from "@mui/joy";
import { memo, useMemo } from "react";
import { DetailsGridValue } from "components/base";
import { useDataRoomDrawerContext } from "components/entities/dataRooms/DataRoomDrawer/contexts";
import {
  dataRoomKindPresentation,
  DataRoomType,
  dataRoomTypeCardPresentation,
  getDataRoomTypeFromTypename,
} from "models";

const DataRoomTypeInfo = memo(() => {
  const { __typename, dataRoomOverviewData, showKind } =
    useDataRoomDrawerContext();
  const kind = dataRoomOverviewData?.kind;
  const { dataRoomTypeTitle, dataRoomTypeIcon } = useMemo(() => {
    const dataRoomType = getDataRoomTypeFromTypename[__typename];

    const { title, icon: dataRoomTypeIcon } =
      dataRoomTypeCardPresentation[dataRoomType];
    const dataRoomTypeTitle =
      showKind && dataRoomType === DataRoomType.MediaInsights && kind
        ? `${title} (${dataRoomKindPresentation[kind]})`
        : title;
    return { dataRoomTypeIcon, dataRoomTypeTitle };
  }, [__typename, showKind, kind]);

  return (
    <DqCard>
      <DqCardContent sx={{ alignItems: "flex-start" }}>
        <Grid xs={12}>
          <Typography gutterBottom={true} level="body-sm" textColor="inherit">
            <strong>Type</strong>
          </Typography>
          <DetailsGridValue icon={dataRoomTypeIcon} value={dataRoomTypeTitle} />
        </Grid>
      </DqCardContent>
    </DqCard>
  );
});
DataRoomTypeInfo.displayName = "DataRoomType";

export default DataRoomTypeInfo;
