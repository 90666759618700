// TODO: Fix joy migration
import {
  faExternalLinkSquare,
  faFileCertificate,
  faKey,
  faMicrochip,
  faShieldCheck,
  faShieldKeyhole,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemContent,
  ListItemDecorator,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { type Theme } from "@mui/material";
import { memo } from "react";
import { makeStyles } from "tss-react/mui";

const usePublishedAndProtectedDialogStyles = makeStyles()((theme: Theme) => ({
  icon: {
    color: theme.palette.secondary.main,
  },
  iconTitleWrapper: {
    padding: theme.spacing(3, 3, 2, 3),
  },
  iconWrapper: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  linkButtonIcon: {
    paddingLeft: theme.spacing(1),
  },
  listIcon: {},
  title: {
    lineHeight: 1.25,
  },
}));

interface PublishedAndProtectedDialogProps {
  open: boolean;
  onClose: () => void;
}

const PublishedAndProtectedDialog: React.FC<
  PublishedAndProtectedDialogProps
> = ({ open, onClose }) => {
  const { classes: publishedAndProtectedDialogClasses } =
    usePublishedAndProtectedDialogStyles();
  return (
    <Modal onClose={onClose} open={open}>
      <ModalDialog>
        <Box className={publishedAndProtectedDialogClasses.iconTitleWrapper}>
          <Box className={publishedAndProtectedDialogClasses.iconWrapper}>
            <FontAwesomeIcon
              className={publishedAndProtectedDialogClasses.icon}
              fixedWidth={true}
              icon={faShieldKeyhole}
              size="5x"
            />
          </Box>
          <Typography
            className={publishedAndProtectedDialogClasses.title}
            level="title-lg"
            textAlign="center"
          >
            <strong>
              This data clean room is published and protected
              <br />
              by confidential computing guarantees
            </strong>
          </Typography>
        </Box>
        <DialogContent>
          <Typography component="div" level="body-sm">
            Data clean room participants can securely provision their datasets.
            Hardware technologies like Intel® SGX and AMD SEV-SNP help to
            protect this data from disclosure or modification by partitioning
            Decentriq Platform application into the enclaves in memory that
            increase security:
            <List>
              <ListItem>
                <ListItemDecorator>
                  <FontAwesomeIcon
                    className={publishedAndProtectedDialogClasses.listIcon}
                    fixedWidth={true}
                    icon={faShieldCheck}
                  />
                </ListItemDecorator>
                <ListItemContent>
                  Enclaves have hardware-assisted confidentiality and
                  integrity-added protections to help prevent access from
                  processes at higher privilege levels.
                </ListItemContent>
              </ListItem>
              <ListItem>
                <ListItemDecorator>
                  <FontAwesomeIcon
                    className={publishedAndProtectedDialogClasses.listIcon}
                    fixedWidth={true}
                    icon={faMicrochip}
                  />
                </ListItemDecorator>
                <ListItemContent>
                  Hardware-enhanced content protection capabilities help content
                  owners with protecting their intellectual property through
                  unaltered or unmodified streaming."
                </ListItemContent>
              </ListItem>
              <ListItem>
                <ListItemDecorator>
                  <FontAwesomeIcon
                    className={publishedAndProtectedDialogClasses.listIcon}
                    fixedWidth={true}
                    icon={faFileCertificate}
                  />
                </ListItemDecorator>
                <ListItemContent>
                  Through attestation services it is also possible to receive
                  verification on the identity of the application running in the
                  enclave.
                </ListItemContent>
              </ListItem>
              <ListItem>
                <ListItemDecorator>
                  <FontAwesomeIcon
                    className={publishedAndProtectedDialogClasses.listIcon}
                    fixedWidth={true}
                    icon={faKey}
                  />
                </ListItemDecorator>
                <ListItemContent>
                  Data is end-to-end encrypted so nobody except data clean room
                  participants — not even Decentriq — can decrypt and read it.
                </ListItemContent>
              </ListItem>
            </List>
          </Typography>
        </DialogContent>
        <Divider />
        <Button
          component="a"
          href="https://www.intel.com/content/www/us/en/developer/tools/software-guard-extensions/attestation-services.html"
          sx={{ borderRadius: 0, marginInline: "var(--Divider-inset)" }}
          target="_blank"
        >
          How Intel® SGX remote attestation works
          <FontAwesomeIcon
            className={publishedAndProtectedDialogClasses.linkButtonIcon}
            fixedWidth={true}
            icon={faExternalLinkSquare}
          />
        </Button>
        <Divider />
        <Button
          component="a"
          href="https://blog.decentriq.com/swiss-cheese-to-cheddar-securing-amd-sev-snp-early-boot-2/"
          sx={{ borderRadius: 0, marginInline: "var(--Divider-inset)" }}
          target="_blank"
        >
          How Decentriq operates on AMD SEV-SNP
          <FontAwesomeIcon
            className={publishedAndProtectedDialogClasses.linkButtonIcon}
            fixedWidth={true}
            icon={faExternalLinkSquare}
          />
        </Button>
        <Divider />
        <DialogActions>
          <Button onClick={onClose}>OK</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

PublishedAndProtectedDialog.displayName = "PublishedAndProtectedDialog";

export default memo(PublishedAndProtectedDialog);
