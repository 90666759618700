import { faEyeSlash, faFileSignature } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Stack, Typography } from "@mui/joy";
import { memo } from "react";

interface EndUserTermsBlockProps {
  onHide: () => void;
}

const EndUserTermsBlock = memo<EndUserTermsBlockProps>(({ onHide }) => (
  <Stack
    gap={1}
    height="100%"
    padding={2}
    sx={({ palette, radius }) => ({
      background: palette.info[100],
      borderRadius: radius.sm,
    })}
  >
    <Stack direction="row" justifyContent="space-between">
      <Typography level="h4">End-user terms</Typography>
      <Button
        onClick={onHide}
        size="sm"
        startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faEyeSlash} />}
        sx={{
          "--Icon-color": "inherit",
        }}
        variant="plain"
      >
        Do not show again
      </Button>
    </Stack>
    <Typography level="body-md">
      Don’t forget to read the End-user terms before using the Decentriq
      Platform.
    </Typography>
    <Button
      color="primary"
      component="a"
      href="https://www.decentriq.com/end-user-terms"
      startDecorator={
        <FontAwesomeIcon fixedWidth={true} icon={faFileSignature} />
      }
      sx={{ mt: "auto" }}
      target="_blank"
      variant="solid"
    >
      End-user terms
    </Button>
  </Stack>
));

EndUserTermsBlock.displayName = "EndUserTermsBlock";

export default EndUserTermsBlock;
