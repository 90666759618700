import { DqCard, DqCardContent } from "@decentriq/components";
import { faUser } from "@fortawesome/pro-light-svg-icons";
import { Typography } from "@mui/joy";
import { memo } from "react";
import { DetailsGridValue } from "components";
import { useDataRoomDrawerContext } from "components/entities/dataRooms/DataRoomDrawer/contexts";

const DataRoomCreatedBy = memo(() => {
  const { showCreatorOrganization, dataRoomOverviewData } =
    useDataRoomDrawerContext();

  const { owner, ownerOrganization } = dataRoomOverviewData || {};
  return (
    <DqCard>
      <DqCardContent sx={{ alignItems: "flex-start" }}>
        <Typography gutterBottom={true} level="body-sm" textColor="inherit">
          <strong>Created by</strong>
        </Typography>
        <DetailsGridValue
          icon={faUser}
          value={`${owner?.email}${
            showCreatorOrganization ? ` (${ownerOrganization?.name})` : ""
          }`}
        />
      </DqCardContent>
    </DqCard>
  );
});

export default DataRoomCreatedBy;
