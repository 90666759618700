import { useAuth0 } from "@auth0/auth0-react";
import { DqEmptyData, DqTable } from "@decentriq/components";
import { useDataRoomsListQuery } from "@decentriq/graphql/dist/hooks";
import { type DataRoomsListQuery } from "@decentriq/graphql/dist/types";
import { faArrowRight, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Stack, Typography } from "@mui/joy";
import { filter } from "lodash";
import { type MRT_ColumnDef } from "material-react-table";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DataRoomDrawerVariant, StateIcon, TimeAgoFormatted } from "components";
import {
  dataRoomPathBuilder,
  dataRoomTypeCardPresentation,
  DataRoomTypeNames,
  getDataRoomTypeFromTypename,
} from "models";
import { type Organization, type User } from "types";
import DataRoomDrawer from "../DataRoomDrawer/DataRoomDrawer";
import { DataRoomsListFilter, FILTER_OPTIONS } from "./components";

const dataRoomsListColumns: MRT_ColumnDef<DataRoomsListItem>[] = [
  {
    Cell: ({ row }) => {
      const {
        original: {
          __typename,
          name,
          userSettings = {
            isArchived: false,
            isFavorite: false,
          },
        },
        original,
      } = row;
      const isStopped = "isStopped" in original && original.isStopped;
      const isLocked =
        __typename === DataRoomTypeNames.PublishedDataRoom ||
        __typename === DataRoomTypeNames.PublishedMediaDataRoom ||
        __typename === DataRoomTypeNames.PublishedLookalikeMediaDataRoom ||
        __typename === DataRoomTypeNames.PublishedMediaInsightsDcr;
      return (
        <Typography
          component="div"
          endDecorator={
            <Stack alignItems="center" spacing={0.5}>
              {userSettings?.isArchived && (
                <FontAwesomeIcon fixedWidth={true} icon={faEyeSlash} />
              )}
            </Stack>
          }
          fontWeight="500"
          level="inherit"
          noWrap={true}
          startDecorator={
            <StateIcon
              status={
                isLocked ? (isStopped ? "STOPPED" : "PUBLISHED") : "DRAFT"
              }
            />
          }
          textColor="inherit"
        >
          <Typography component="span" noWrap={true}>
            {name}
          </Typography>
        </Typography>
      );
    },
    accessorKey: "name",
    header: "Name",
    id: "name",
    size: 270,
  },
  {
    Cell: ({ cell }) => {
      const createdAt = cell.getValue<string>();
      return createdAt ? (
        <TimeAgoFormatted date={createdAt} style={{ whiteSpace: "nowrap" }} />
      ) : (
        "—"
      );
    },
    accessorKey: "createdAt",
    enableGlobalFilter: false,
    header: "Creation date",
    id: "createdAt",
  },
  {
    Cell: ({ cell }) => {
      const owner =
        cell.getValue<User & { organization: Organization }>() || {};
      return owner?.organization?.name || "-";
    },
    accessorKey: "owner",
    enableGlobalFilter: false,
    header: "Created by",
    id: "createdBy",
  },
  {
    Cell: ({ cell }) => {
      const __typename = cell.getValue() as DataRoomTypeNames;
      const dataRoomType = getDataRoomTypeFromTypename[__typename];
      const { title: dataRoomTypeTitle, icon: dataRoomTypeIcon } =
        dataRoomTypeCardPresentation[dataRoomType];
      return (
        <Stack alignItems="center" direction="row">
          <FontAwesomeIcon fixedWidth={true} icon={dataRoomTypeIcon} />
          <Typography level="body-sm">{dataRoomTypeTitle}</Typography>
        </Stack>
      );
    },
    accessorKey: "__typename",
    enableGlobalFilter: false,
    header: "Type",
    id: "__typename",
  },
  {
    Cell: ({ row }) => {
      const navigate = useNavigate();
      const { id, __typename } = row.original;
      const dataRoomLink = dataRoomPathBuilder(
        id,
        __typename as DataRoomTypeNames
      );
      return (
        <Button
          endDecorator={
            <FontAwesomeIcon fixedWidth={true} icon={faArrowRight} />
          }
          onClick={() => navigate(dataRoomLink, { replace: false })}
        >
          Open
        </Button>
      );
    },
    enableResizing: false,
    grow: false,
    header: "Actions",
    id: "actions",
    muiTableBodyCellProps: { sx: { p: 0 } },
    size: 140,
  },
];

type DataRoomsListItem = DataRoomsListQuery["dataRooms"]["nodes"][number];

const DataRoomsList: React.FC = () => {
  const navigate = useNavigate();
  const { user = {} } = useAuth0();
  const { email: currentUserEmail } = user || {};
  const {
    data: { dataRooms: { nodes: dataRoomsList = [] } = {} } = {},
    loading,
  } = useDataRoomsListQuery({ fetchPolicy: "network-only" });
  const { dataRoomId: selectedDataRoomId } = useParams();
  const selectedDataRoom = dataRoomsList.find(
    ({ id }) => id === selectedDataRoomId
  );
  // Showing only dataroom types that are passed in the prop. If empty array - show all
  const [filterKey, setFilterKey] = useState(FILTER_OPTIONS.active.key);

  const filteredDataRoomsList = useMemo(() => {
    const {
      equalsTo,
      field: filterField,
      value: filterValue,
    } = FILTER_OPTIONS[filterKey];
    return filterKey === FILTER_OPTIONS.all.key
      ? dataRoomsList
      : (filter(
          dataRoomsList,
          equalsTo
            ? (dataRoom) => equalsTo(dataRoom, currentUserEmail)
            : [filterField, filterValue]
        ) as DataRoomsListItem[]);
  }, [dataRoomsList, filterKey, currentUserEmail]);
  // Empty state
  if (!loading && !dataRoomsList.length) {
    return (
      <DqEmptyData title="You haven't created any data clean rooms yet." />
    );
  }
  return (
    <Box
      sx={{
        alignItems: "stretch",
        backgroundColor: "common.white",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "stretch",
        overflow: "hidden",
      }}
    >
      <DqTable
        columns={dataRoomsListColumns}
        data={filteredDataRoomsList}
        enableBatchRowSelection={false}
        enableGlobalFilter={true}
        enableMultiRowSelection={false}
        enableRowSelection={true}
        enableSelectAll={false}
        enableSorting={true}
        enableTopToolbar={true}
        getRowId={(row) => row.id}
        initialState={{
          showGlobalFilter: true,
          sorting: [{ desc: true, id: "createdAt" }],
        }}
        localization={{
          noRecordsToDisplay: "No data clean rooms found",
        }}
        muiSearchTextFieldProps={{
          placeholder: "Search data clean rooms",
        }}
        muiTableBodyRowProps={({
          row: { getToggleSelectedHandler, original },
        }) => {
          return {
            onClick: () => {
              getToggleSelectedHandler();
              navigate({ pathname: `/datarooms/${original.id}` });
            },
            sx: {
              "& > .MuiTableCell-root:first-child": { pl: 2 },
              "& > .MuiTableCell-root:last-child": { pr: 2 },
              cursor: "pointer",
            },
          };
        }}
        muiTableHeadRowProps={{
          sx: {
            "& > .MuiTableCell-root:first-child": { pl: 2 },
            "& > .MuiTableCell-root:last-child": {
              "& .Mui-TableHeadCell-ResizeHandle-Wrapper": {
                right: "-1rem",
              },
              pr: 2,
            },
          },
        }}
        muiTablePaperProps={{
          sx: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            height: "100%",
            overflow: "hidden",
            width: "100%",
          },
        }}
        renderTopToolbarCustomActions={() => (
          <DataRoomsListFilter
            filterKey={filterKey}
            setFilterKey={setFilterKey}
          />
        )}
        state={{
          columnVisibility: { "mrt-row-select": false },
          isLoading: loading,
          rowSelection: selectedDataRoomId
            ? { [selectedDataRoomId]: true }
            : {},
          showLoadingOverlay: false,
          showProgressBars: false,
          showSkeletons: loading,
        }}
      />
      <DataRoomDrawer
        __typename={selectedDataRoom?.__typename as DataRoomTypeNames}
        dataRoomId={selectedDataRoom?.id}
        onClose={() => navigate({ pathname: "/datarooms" })}
        open={Boolean(selectedDataRoom?.id)}
        variant={DataRoomDrawerVariant.DcrsList}
      />
    </Box>
  );
};

export default DataRoomsList;
