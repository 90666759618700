import { DqCard, DqCardContent } from "@decentriq/components";
import { faArrowRight, faBook } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Stack, Typography } from "@mui/joy";
import { memo } from "react";

interface LearnMoreBlockType {
  title: string;
  description: string;
  link: string;
}

const LEARN_MORE_BLOCKS: LearnMoreBlockType[] = [
  {
    description:
      "Decentriq is a software platform offering data clean rooms (DCRs) that enable users to collaborate on data with minimal risk.",
    link: "https://docs.decentriq.com/welcome-to-decentriq",
    title: "What is the Decentriq Platform?",
  },
  {
    description:
      "Decentriq's Media Data Clean Rooms (Media DCRs) provide an intuitive no-code interface to use first-party data for gaining insights and optimizing advertising campaigns.",
    link: "https://docs.decentriq.com/media-dcr/getting-started",
    title: "Getting started with Media DCR",
  },
  {
    description:
      "The Advanced Analytics DCR is an environment designed to enable collaborative data analysis where the use of data can be precisely controlled by the data owners.",
    link: "https://docs.decentriq.com/advanced-analytics-dcr/getting-started",
    title: "Getting started with Advanced Analytics DCR",
  },
];

const LearnMoreBlock = memo(() => {
  return (
    <Grid xs={12}>
      <Typography
        fontWeight={500}
        level="body-md"
        mb={1}
        startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faBook} />}
      >
        Learn more
      </Typography>
      <Stack direction={{ md: "row", xs: "column" }} gap={1}>
        {LEARN_MORE_BLOCKS.map(({ link, title, description }) => (
          <DqCard key={link} sx={{ flex: 1, gap: 2 }}>
            <DqCardContent
              sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
            >
              <Typography gutterBottom={true} level="title-md">
                {title}
              </Typography>
              <Typography level="body-sm">{description}</Typography>
            </DqCardContent>
            <Button
              color="primary"
              component="a"
              endDecorator={
                <FontAwesomeIcon
                  fixedWidth={true}
                  fontSize={16}
                  icon={faArrowRight}
                />
              }
              href={link}
              target="_blank"
              variant="soft"
            >
              Read more
            </Button>
          </DqCard>
        ))}
      </Stack>
    </Grid>
  );
});

LearnMoreBlock.displayName = "LearnMoreBlock";

export default LearnMoreBlock;
