import { DqCard, DqCardContent, DqLoader } from "@decentriq/components";
import {
  faClock as faClockLight,
  faCopy as faCopyLight,
  faHashtagLock as faHashtagLockLight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconButton,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { memo } from "react";
import { DetailsGridValue } from "components";
import { useDataLabContext } from "features/dataLabs/contexts";
import {
  type DataLabDataset,
  matchingIdTypeAndHashingAlgorithmPresentation,
} from "features/dataLabs/models";
// TODO: We should refactor this to do not import something from features inside the components folder
import DatasetsInformation from "features/datasets/components/DatasetsInformation/DatasetsInformation";
import { useCopyToClipboard } from "hooks";

interface DataLabDetailsProps {
  id: string;
}

const DataLabDetails: React.FC<DataLabDetailsProps> = () => {
  const {
    dataLab: { data, loading },
  } = useDataLabContext();
  const [, copyToClipboard] = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();

  if (!data || loading) {
    return (
      <DqLoader
        size="sm"
        sx={{ height: "100%", justifyContent: "center", width: "100%" }}
      />
    );
  }

  const {
    id,
    updatedAt,
    createdAt,
    matchingIdFormat,
    requireDemographicsDataset,
    requireEmbeddingsDataset,
    matchingIdHashingAlgorithm,
    requireSegmentsDataset,
    numEmbeddings,
    segmentsDataset,
    demographicsDataset,
    embeddingsDataset,
    usersDataset,
  } = data;
  const datasets: DataLabDataset[] = [
    segmentsDataset,
    usersDataset,
    demographicsDataset,
    embeddingsDataset,
  ].filter(Boolean) as DataLabDataset[];
  const dateFormat = "dd.MM.yyy, HH:mm";
  return (
    <Stack>
      {!!createdAt && (
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <Typography gutterBottom={true} level="body-sm" textColor="inherit">
              <strong>Created at</strong>
            </Typography>
            <DetailsGridValue
              icon={faClockLight}
              value={format(new Date(createdAt), dateFormat)}
            />
          </DqCardContent>
        </DqCard>
      )}
      {Boolean(updatedAt) && (
        <DqCard>
          <DqCardContent sx={{ alignItems: "flex-start" }}>
            <Typography gutterBottom={true} level="body-sm" textColor="inherit">
              <strong>Last modified</strong>
            </Typography>
            <DetailsGridValue
              icon={faClockLight}
              value={format(new Date(updatedAt), dateFormat)}
            />
          </DqCardContent>
        </DqCard>
      )}
      <DqCard>
        <DqCardContent sx={{ alignItems: "flex-start" }}>
          <Typography gutterBottom={true} level="body-sm" textColor="inherit">
            <strong>Datalab ID</strong>
          </Typography>
          <Stack alignItems="center" flexDirection="row" width="100%">
            <FontAwesomeIcon fixedWidth={true} icon={faHashtagLockLight} />
            <Tooltip title={id}>
              <Typography level="body-sm" noWrap={true}>
                {id}
              </Typography>
            </Tooltip>
            <Tooltip title={<span>Copy Datalab id</span>}>
              <IconButton
                onClick={() => {
                  if (id) {
                    void copyToClipboard(id);
                    enqueueSnackbar("Datalab id copied");
                  }
                }}
                size="sm"
                sx={{ marginLeft: "auto" }}
              >
                <FontAwesomeIcon fixedWidth={true} icon={faCopyLight} />
              </IconButton>
            </Tooltip>
          </Stack>
        </DqCardContent>
      </DqCard>
      <DqCard>
        <DqCardContent sx={{ alignItems: "flex-start" }}>
          <Typography level="body-sm" textColor="inherit">
            <strong>Data Configuration</strong>
          </Typography>
          <List
            sx={{
              "--ListItem-minHeight": 0,
              paddingBottom: 0,
            }}
          >
            <ListItem>
              <Typography level="body-sm" textColor="inherit">
                <strong>Matching ID:</strong>{" "}
                {matchingIdTypeAndHashingAlgorithmPresentation({
                  matchingIdFormat,
                  matchingIdHashingAlgorithm,
                })}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography level="body-sm" textColor="inherit">
                {/* Having Matching dataset is always required while creating a Datalab */}
                <strong>Matching data:</strong> Yes
              </Typography>
            </ListItem>
            <ListItem>
              <Typography level="body-sm" textColor="inherit">
                <strong>Segments data:</strong>{" "}
                {requireSegmentsDataset ? "Yes" : "No"}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography level="body-sm" textColor="inherit">
                <strong>Demographics data:</strong>{" "}
                {requireDemographicsDataset ? "Yes" : "No"}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography level="body-sm" textColor="inherit">
                <strong>Embeddings data:</strong>{" "}
                {requireEmbeddingsDataset ? "Yes" : "No"}
              </Typography>
            </ListItem>
            {requireEmbeddingsDataset && (
              <ListItem>
                <Typography level="body-sm" textColor="inherit">
                  <strong>Number of embeddings:</strong> {numEmbeddings}
                </Typography>
              </ListItem>
            )}
          </List>
        </DqCardContent>
      </DqCard>
      <DqCard>
        <DqCardContent sx={{ alignItems: "flex-start" }}>
          <Typography gutterBottom={true} level="body-sm" textColor="inherit">
            <strong>Datasets</strong>
          </Typography>
          <DatasetsInformation
            datasets={datasets}
            emptyText="No datasets provisioned"
            loading={loading}
          />
        </DqCardContent>
      </DqCard>
    </Stack>
  );
};

DataLabDetails.displayName = "DataLabDetails";

export default memo(DataLabDetails);
