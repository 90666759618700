import { DqCard, DqCardContent } from "@decentriq/components";
import { Alert, Chip, Stack, Typography } from "@mui/joy";
import { memo } from "react";
import {
  EstimatedAudienceSize,
  QualityScore,
  useMediaDataRoom,
} from "features/mediaDataRoom";
import { useLookalikeAdvertiserAudienceGenerator } from "features/mediaDataRoom/containers/LookalikeAdvertiserAudienceGenerator";
import { AudienceQualityStatisticsDrawer } from "features/mediaDataRoom/containers/LookalikeAdvertiserAudienceGenerator/components";
import { PositiveCurveScoreBar } from "./components";

const AudienceQualityStatistics = memo(() => {
  const { selectedAudienceStatistics } =
    useLookalikeAdvertiserAudienceGenerator();
  const {
    testSetPositiveExamplesSize,
    testSetSize,
    estimatedAudienceSize,
    audienceQualityScore,
    excludedAudienceSize,
    computeState: { loading: isStatisticsComputationLoading },
  } = selectedAudienceStatistics!;
  const {
    supportedFeatures: { showAbsoluteValues },
  } = useMediaDataRoom();
  return (
    <Stack direction="column" flex={1} spacing={1.5}>
      <Typography fontWeight={600} level="title-md" mb={1.5} textAlign="center">
        Lookalike audience quality statistics
        <Chip color="warning" sx={{ ml: 1 }}>
          Beta
        </Chip>
      </Typography>
      {isStatisticsComputationLoading && (
        <Alert color="neutral" sx={{ mb: 1 }}>
          <div>
            <Typography level="title-md" sx={{ color: "inherit" }}>
              Audience is computing
            </Typography>
            <Typography level="body-sm" sx={{ color: "inherit" }}>
              This may take up to an hour. You can still save the audience
              during this time, but results won't be immediate.
            </Typography>
          </div>
        </Alert>
      )}
      {showAbsoluteValues && (
        <EstimatedAudienceSize
          estimatedAudienceSize={estimatedAudienceSize}
          excludedAudienceSize={excludedAudienceSize}
          loading={isStatisticsComputationLoading}
        />
      )}
      <QualityScore
        loading={isStatisticsComputationLoading}
        qualityScore={audienceQualityScore}
      />
      <DqCard sx={{ width: "100%" }}>
        <DqCardContent>
          <PositiveCurveScoreBar />
        </DqCardContent>
      </DqCard>
      {!isStatisticsComputationLoading &&
        showAbsoluteValues &&
        (testSetPositiveExamplesSize || testSetSize) < 50 && (
          <Alert color="warning" sx={{ mt: 1 }}>
            There were too few individuals in the overlapping audience to create
            a large holdout group for testing. This may result in a less
            accurate assessment of model quality.
          </Alert>
        )}
      <AudienceQualityStatisticsDrawer />
    </Stack>
  );
});
AudienceQualityStatistics.displayName = "AudienceQualityStatistics";

export default AudienceQualityStatistics;
