import { DqCard, DqCardContent, DqTable } from "@decentriq/components";
import { faUsers } from "@fortawesome/pro-light-svg-icons";
import { Box, Typography } from "@mui/joy";
import { type MRT_ColumnDef } from "material-react-table";
import { memo } from "react";
import { DetailsGridIcon, DetailsGridValue } from "components/base";
import { useDataRoomDrawerContext } from "components/entities/dataRooms/DataRoomDrawer/contexts";
import ParticipantsPopover from "../ParticipantsPopover/ParticipantsPopover";

const dataRoomParticipantsColumns: MRT_ColumnDef<{
  id: string;
  userEmail: string;
}>[] = [
  {
    accessorKey: "userEmail",
    header: "Email",
    id: "email",
    minSize: 220,
  },
  {
    accessorKey: "organization.name",
    header: "Organization",
    id: "organization",
  },
];

const DataRoomParticipants = memo(() => {
  const { dataRoomOverviewData, showDetailedParticipantsInfo } =
    useDataRoomDrawerContext();

  const participants = dataRoomOverviewData?.participants ?? [];
  const owner = dataRoomOverviewData?.owner;

  return (
    <DqCard>
      <DqCardContent sx={{ alignItems: "stretch" }}>
        <Typography gutterBottom={true} level="body-sm" textColor="inherit">
          <strong>Participants</strong>
        </Typography>
        {showDetailedParticipantsInfo ? (
          <DqTable columns={dataRoomParticipantsColumns} data={participants} />
        ) : participants.length ? (
          <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
            <DetailsGridIcon icon={faUsers} />
            {participants.length === 1 ? (
              <DetailsGridValue value={participants[0]?.userEmail} />
            ) : (
              <ParticipantsPopover
                ownerEmail={owner?.email}
                participants={participants}
              />
            )}
          </Box>
        ) : (
          "—"
        )}
      </DqCardContent>
    </DqCard>
  );
});

export default DataRoomParticipants;
